import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "brands-page"
    }}>{`Brands Page`}</h1>
    <h2 {...{
      "id": "brands-page-layout"
    }}>{`Brands Page Layout`}</h2>
    <p><img alt="theme-editor-brands-page-layout" src={require("../img/theme-editor-brands-page-layout.png")} /></p>
    <p>{`Configure Brands Page layout to display alphabet table and grouped by letter in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Products`}</strong>{` > `}<strong parentName="p">{`Brands pages`}</strong>{` > `}<strong parentName="p">{`Layout`}</strong>{` = `}<inlineCode parentName="p">{`A-Z Table`}</inlineCode>{`. Save & Publish.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      